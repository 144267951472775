@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.index-page {
    padding-bottom: calc(60px + constant(safe-area-inset-bottom));
    padding-bottom: calc(60px + env(safe-area-inset-bottom));
}

:deep(.q-page.mobile) {
    min-height: 100px !important;
}

.carousel {
    &__title {
        @media screen and (max-width: $breakpoint-mobile) {
            font: {
                size: 16px;
                weight: 18px;
            }
        }
    }
}

.carousel__title {
    @media (max-width: 1280px) {
        font-size: 1rem;
    }
}

.fixed {
    top: 0;
    z-index: 1;
    width: 100%;
    background-color: #fbfafa !important;
}

.margin-top {
    margin-top: calc(v-bind('addresHeight') + 160px + env(safe-area-inset-bottom));
}

.address-card {
    width: calc(100% - 32px);
    height: 190px;
    top: 90px;
    left: 16px;
    position: fixed;
    z-index: 9999;
}

